.shoppagecls .shopcards {
  
    cursor: pointer;
}
.shoppagecls .shopcards:hover{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2), 0 6px 2px 0 rgba(0, 0, 0, .19);

}
.shoppagecls .cardimg {
    overflow: hidden;
}



@media screen and (max-width: 760px){
    .shoppagecls .productname {
        font-size: 15px;
        text-align: left !important;
    }
    .shoppagecls .prdctimg {
       width: 100%;
       height: 100px;
    
    }
.shoppagecls .price{
    font-size: 20px;
}
   .shopcards .shopcards {
     width: 100%;
  
    
  }
    .shoppagecls .row2cart{
        text-align: left !important;
    }
    .shoppagecls .cardbtn{
        font-size: 10px !important;
    }
    .notification{
        right: 0 !important;
        left: 0 !important;
    }
     .popupcart {
        width: 100% !important;
    }
}
@media screen and (min-width: 768px) {
    .shoppagecls .productname {
        height: 50px;
      
    }
   .shoppagecls .prdctimg {
        width: 100%;
            height: 250px;
    
   }
   .shopcards .shopcards {
       width: 100%;
       height: 400px !important;

   }
 
}



 .popupcart{
    width: 500px;
    border: none;
    box-shadow:0 5px 8px rgba(0,0,0,0.15) ;
}
.cartpopupbtn1{
    border: none !important;
    background-color: #652700 !important;
    color: white !important;
}
.cartpopupbtn2 {
    border: none !important;
    background-color: #000066!important;
    color: white !important;
}