.cardimgscls .text-1{
    color: #8F3300;
    font-family: Roboto;
    font-weight: 800;
}
.cardimgscls .text-2{
    color: #000;
    text-align: center;
    font-family: Open Sans;
    font-weight: 400;
    line-height: 165.4%; /* 29.772px */
}

@media screen and (min-width: 992px) {
.cardimgscls .about-cards {
        height: 500px;
    }
}