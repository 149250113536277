/* Import the image */
/* No need to import the image in CSS, it's only needed in JS */

/* Define a class for applying background image */
.bg-image {
    background-image: url('../../Images/image 55.png');
    /* Replace with the correct relative path */
    background-size: cover;
    background-position: center;
}
.contactcls .row2 h3{
    color: #652700;
    font-family: Roboto;
    font-weight: 800;
}
.contactcls .row2 p {
    color: #525c60;
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 400;
}
.contactcls .gicon,
.contactcls .iconsrow .icons,
.ficon {
    background-color: #ebf6ee;
    color: #652700;
    height: 30px;
    width: 30px;
}
.contactcls .row-3img .titlelocation {
    color: #b27b60;
    font-family: Yellowtail;
    font-size: 30px;
}
.adrstext,
.contactcls .row-3img .Establishedtext {
    color: #8f3300;
    font-family: Open Sans;
    font-size: 18px;
}
.contactcls .formlabel {
    color: #652700;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 600;
}
.contactcls input:focus,.contactcls textarea:focus{
    background: none;
    outline: none;
    box-shadow: none;
}
.contactcls .labelholder {
    color: #ababab;
    font-family: Roboto;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
}
.contactcls button {
    align-items: center;
    background: #652700;
    color: #fff;
    justify-content: center;
}
@media (max-width: 767px){
    .contactcls .cardlocation {
            background-image: url("../../Images/leaf.png");
            background-position: 50%;
            background-size: cover;
            border-radius: 30px;
        }
}

@media (min-width: 768px)  {
    .contactcls .row-3img .leafimgdiv{
 position: relative;
    }
.contactcls .row-3img .cardlocation {
            position: absolute;
            right: 0;
            top: 0;
 }
    
}
/* Apply background image only for small screens */
@media (max-width: 767px) {
    .bg-image {
        background-image: url('../../Images/image 55.png');
        /* Replace with the correct relative path */
        background-size: cover;
        background-position: center;
        padding: 50px;
        /* Adjust as needed */
    }
}