/* Add this CSS to your Bottombar.css */

/* Style for the active link */
.bottompage .b-link.active {
    background-color: #eae6dc;
    color: white;
 
}

.bottompage .active {
    background-color: #eae6dc;
 
}
@media screen and (max-width: 280px) {
.bottompage .bottomlinks {
    gap: 5px !important;
}
}

